<script>
import html2canvas from 'html2canvas';
// eslint-disable-next-line no-unused-vars
import jsPDF from 'jspdf';
import "./../assets/Merriweather-Regular-normal"
import { mapActions, mapGetters } from 'vuex';
import Chart from 'chart.js';
export default {
    computed: { ...mapGetters(['companys', 'usersSameCompany']) },
    data() {
        return {
            mainName: null,
            mainTitle: null,
            mainText: null,
            company: null,
            companyList: [],
            items: [],
            chartItems:[],
            itemsFromServer: [],
            selectedItemFromServer: null,
            totalParticipants: 0,
            participantsNum: 0,
            participants: [],
            letterSpacing: false,
            showUser: false,
            answerIndex: 0,
            chartTest: { paja: 1 },
            options: {
                plug: true,
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            },
        }
    },
    methods: {
        ...mapActions(['getCompanies', 'getCollegues']),
        isMobile() {
            if(window.innerWidth < 768){
                return true
            } else {
                return false
            }
        },
        submit() {
            
        },
        async getSavedQuestionnaires(companyDropdown) {
            this.selectedItemFromServer = null;
            this.mainName = null;
            this.companys.forEach(company => {
                if (company.name == companyDropdown) {
                    this.itemsFromServer = company.customQuestionnaire
                }
            });
            await this.getCollegues(this.company);
        },
        async getSelectedItemFromServer(selectedItemFromServer) {            
            this.itemsFromServer.forEach(item => {
                if (item.name == selectedItemFromServer) {
                    this.items = item.questions;
                    this.mainTitle = item.title;
                    this.mainText = item.text;
                    this.mainName = item.name;
                    this.items.forEach(ele => {
                        if (ele.isTextAnswer) {
                            ele.userAnswer = [];
                        } else
                            if (ele.isNumberAnswer) {
                                ele.userAnswer = []
                                for (let i = 0; i < ele.answers.length; i++) {
                                    ele.userAnswer.push({ value: 0 })
                                }
                            } else
                                if (ele.isCheckAnswer) {
                                    ele.userAnswer = []
                                    for (let i = 0; i < ele.answers.length; i++) {
                                        ele.userAnswer.push({ value: 0 })
                                    }
                                }
                    })
                }
            });
            this.participantsNum = 0;
            this.participants = [];
            this.usersSameCompany.forEach(user => {
                
                user.customUpitnikSchema.forEach(ele => {
                    if (ele.name == this.mainName && ele.finished == true) {
                        this.participantsNum += 1
                        ele.user = user.name
                        this.participants.push(ele)
                    }
                })

            });
            // calculating number of users
            if(this.company == "DIS - Istraživanje stavova zaposlenih 2023."){
                this.totalParticipants = 1409;
            } else {
                this.totalParticipants = this.usersSameCompany.length
            }
            //calculation for how many users checked something
            for (let i = 0; i < this.participants.length; i++) {
                for (let j = 0; j < this.participants[i].answers.length; j++) {
                    if (typeof this.participants[i].answers[j] === 'string') {
                        const item = {
                            answer: this.participants[i].answers[j],
                            user: this.participants[i].user
                        }
                        this.items[j].userAnswer.push(item)
                        
                    }
                    for (let k = 0; k < this.participants[i].answers[j].length; k++) {
                        if (this.participants[i].answers[j][k].checked == true) {
                            this.items[j].userAnswer[k].value += 1
                        } else if (this.participants[i].answers[j][k].number) {
                            this.items[j].userAnswer[k].value += this.participants[i].answers[j][k].number
                        }
                    }
                }
            }
            this.items.forEach(item => {
                let sum = 0;
                item.userAnswer.forEach(ele => {
                    sum += ele.value
                })
                item.userAnswerSum = sum
            })
            this.items.forEach((item) => {
                const object = item.answers.reduce((obj, key, i) => {
                    let keySubstring = key;
                    if(key.length > 25) {
                       keySubstring = key.substring(0, 25) + "..."
                    }
                    obj[keySubstring] = item.userAnswer[i].value;
                    return obj;
                    }, {});
                    item.chartAnswers = object; 
            })
            
        },
        makePDF() {
            this.letterSpacing = true;
            setTimeout(() => {
                this.letterSpacing = false;
            }, 2000)
            window.html2canvas = html2canvas;
            var doc = new jsPDF("l", "pt", "a4");
            doc.setFont("Merriweather-Regular", "normal");
            doc.html(document.querySelector("#questionnaire"), {
                html2canvas: {
                    scale: 1,
                    letterRendering: true,
                    //scrollY:0
                },
                callback: function (pdf) {
                    pdf.save("mypdf.pdf");
                },
            });
        },

    },
    async created() {
        await this.getCompanies();
        this.companys.forEach(company => {
            if (company.name != "admin") {
                this.companyList.push(company.name);
            }

        });
        Chart.plugins.register({
            beforeDraw: function (stazGraph) {
                var ctx = stazGraph.chart.ctx;
                if (stazGraph.config.options.plug == true) {
                    ctx.fillStyle = "#dddddd";//dddddd
                    ctx.fillRect(0, 0, stazGraph.chart.width, stazGraph.chart.height);
                }
            }
        });

    },
}

</script>
<template>
    <div id="company-dropdown" class="mt-2 text-center">
        <h2>Izveštaji upitnika</h2>
        <div class="d-flex justify-content-center mb-3">
            <label class="pt-2 fw-bold p-0 m-0 text-nowrap">Za kompaniju:
                <select @change="getSavedQuestionnaires(company)" name="type" id="type" v-model="company">
                    <option v-for="(company, i) in companyList" :key="i">{{ company }}</option>
                </select>
            </label>
        </div>

    </div>
    <div id="questionnaire-dropdown" v-if="itemsFromServer && company" class="w-100 d-flex justify-content-center pb-4">
        <label v-if="itemsFromServer.length > 0" class="pt-2 fw-bold p-0 m-0 text-nowrap">Postojeći upitnici:
            <select @change="getSelectedItemFromServer(selectedItemFromServer)" name="type" id="type"
                v-model="selectedItemFromServer">
                <option :class="item.active ? 'cc' : 'bb'" v-for="(item, i) in itemsFromServer" :key="i">{{ item.name }}
                </option>
            </select>

        </label>
        <p v-if="itemsFromServer.length == 0">Ne postoje snimljeni upitnici za izabranu kompaniju!</p>
        
    </div>
    <div class="d-flex justify-content-center w-100">
    <button v-if="!showUser && itemsFromServer && company" @click="showUser = !showUser" class="btn btn-primary">Prikaži ime korisnika</button>
        <button v-if="showUser && itemsFromServer && company" @click="showUser = !showUser" class="btn btn-warning">Ugasi ime korisnika</button>
    </div>
    <div style="font-family: Merriweather-Regular" id="questionnaire"
        :class="letterSpacing ? 'letterSpacing' : 'align-items-center'" class="d-flex flex-column justify-content-center ">
        <div v-if="selectedItemFromServer" class="izvestaj d-flex align-items-center justify-content-center">
            <div class="d-grid">
                <h1 class="text-center fw-bold">{{ this.mainTitle }}</h1>
                <h3 v-if="selectedItemFromServer">Broj zaposlenih koji su popunili upitnik: <span class="fw-bold">{{
                    participantsNum }}<!-- /{{ this.totalParticipants }} --><!-- ({{ Math.round((participantsNum /
        this.totalParticipants) * 100) }}%) --></span></h3>
                <!--  <span v-if="this.mainText" class="mx-5">{{ this.mainText }}</span> -->
                
            </div>
            
        </div>
            
        <div >
            <div v-for="(item, i) in items" :key="i" class="answer d-flex flex-column">
                <h3 class="fw-bold">{{ item.questionText }}</h3>
                <div :class="item.answers.length > 5 || isMobile() ? '' : 'd-flex'">
                <!-- RENDER -->
                <table v-if="item.isPredetermentAnswers" :class="item.answers.length > 5 || isMobile() ? 'w-100' : 'w-50'" class="mt-5">
                    <tr>
                        <th>Odgovor</th>
                        <th v-if="item.isCheckAnswer">Broj Zaposlenih</th>
                        <th v-if="item.isNumberAnswer">Broj Poena</th>
                        <th v-if="item.isCheckAnswer">Procenat Zaposlenih</th>
                        <th v-if="item.isNumberAnswer">Procenat Poena</th>
                    </tr>
                    <tr v-for="(answer, i) in item.answers" :key="i">
                        <td>{{ answer }}</td>
                        <td>{{ item.userAnswer[i].value }}</td>
                        <td>{{ Math.round((item.userAnswer[i].value / item.userAnswerSum) * 100) }} %</td>
                    </tr>
                </table>
                <!-- <div v-if="item.isPredetermentAnswers" class="px-3 mx-5 d-flex justify-content-left">
                    <div class="d-grid">
                        <div v-for="(answer, i) in item.answers" :key="i" class="d-inline-flex">
                            <div class="d-flex align-items-center mx-2">
                                <span class="ms-2">{{ answer }}: <span class="mx-1 fw-bold">{{ item.userAnswer[i].value }}</span>|<span class="mx-1 fw-bold">{{Math.round((item.userAnswer[i].value / item.userAnswerSum) *100) }} %</span></span>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- RENDER END -->
                <div v-if="item.isPredetermentAnswers" :class="item.answers.length > 5 || isMobile() ? 'w-100' : 'w-50'" class="mt-5">
                <bar-chart :data="item.chartAnswers" :library="options"></bar-chart>
             </div>
                 </div>
                <!-- RENDER Text Area -->
                <div v-if="item.isTextAnswer" :class="isMobile() ? 'mx-1' : 'mx-5'"  class="my-3 ">
                    <div v-for="(answer, j) in item.userAnswer" :key="j" class="d-grid">
                        <p v-if="answer.answer" :class="isMobile() ? 'ms-1' : 'ms-4'" class="p-0 pb-2 m-0">-<span v-if="showUser" class="font-weight-bold">{{ answer.user }}:</span> {{ answer.answer }} </p>
                    </div>
                </div>
                
                <!-- RENDER Text Area -->
            </div>
            <div v-if="selectedItemFromServer && !isMobile()" id="buttons" class="mx-3 mb-5 d-flex flex-inline">
                <div v-if="!letterSpacing" class="w-50 d-flex justify-content-center pe-1"><button class="btn btn-primary w-100"
                        @click="makePDF()">MakePDF</button></div>
                <div v-if="!letterSpacing" class="w-50 d-flex justify-content-center ps-1"><button class="btn btn-success w-100"
                        onclick="window.print()">Print</button></div>

            </div>
        </div>

    </div>
</template>
<style scoped>
.font-weight-bold{
    font-weight: bold;
}
.izvestaj-width{
    max-width: 800px;
    width: 100%;
}
.izvestaj {
    max-width: 800px;
    height: 580px;
    background-color: #77bbff;
    border-style: solid;
    border-color: black;
    border-width: medium;
    border-radius: 15px;
    padding-top: 235px;
    text-align: center;
    margin-left: 15px;
    margin-top: 15px;
    margin-right: 15px;
    padding: 15px;
}

.questions-area {
    background-color: #FFDEE9;
    background-image: linear-gradient(0deg, #FFDEE9 0%, #B5FFFC 100%);
}

.questions-area-pdf {
    background-color: #B5FFFC;
}

.textarea-invisible {
    border: none;
    background-color: transparent;
    resize: none;
}

.text-area {
    height: 100px;
}

.bb {
    background-color: #ffaaaa;
}

.cc {
    background-color: #aaffaa;
}

.answer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    gap: 0px 0px;
    background-color: #dddddd;
    border-style: solid;
    border-color: black;
    border-width: medium;
    border-radius: 15px;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 20px;
    padding: 15px;
    max-width: 800px;
    min-height: 575px;

}

.table-pdf {
    border: 2px solid black;
    border-radius: 8px;
    text-align: center;
    margin: auto;
    box-shadow: rgb(38, 57, 77) 0px 10px 20px -10px;
    background-color: #f5e1f5;
}

table {
    border: 2px solid black;
    text-align: center;
}

td,
th {
    border: 1px solid black;
    text-align: center;
    padding: 5px;
}

tr:nth-child(even) {
    background-color: #aaaacc;
}

tr:nth-child(odd) {
    background-color: #ccccff;
}

tr:hover {
    background-color: #afa;
}

.letterSpacing {
    letter-spacing: 2px;
}

@media print {
    body {
        overflow: hidden;
    }

    #company-dropdown * {
        visibility: hidden;
    }

    #questionnaire-dropdown * {
        visibility: hidden;
    }

    #buttons {
        visibility: hidden;
    }

    #questionnaire {
        margin-top: -190px;
    }
}
@media screen and (max-width: 768px) {
  .izvestaj{
    max-width: 370px;
  }
  .answer{
    width: 400px;
  }
 
}
</style>